<template>
  <div class="mb-2">
    <FloatLabel :label="label" :optional="optional">
      <div
        class="input-wrapper d-flex justify-content-between align-items-center"
        :class="[`${error ? 'invalid' : ''} ${selectDisabled ? 'disabled' : ''}`]"
      >
        <input
          type="text"
          id="manual-date-picker"
          v-model="val"
          :placeholder="placeholder"
          class="shadow-none form-control"
          :class="[`${error ? 'invalid' : ''}`]"
          :maxlength="format.length"
          v-on:change="handleInputChange"
          :disabled="selectDisabled || false"
          inputmode="numeric"
        />
        <img
          id="caret"
          src="../../assets/images/icons/ic_help_circle.svg"
          style="width: 20px; height: 20px; z-index: 1"
          v-on:mouseenter="hoverTooltip"
          v-on:mouseleave="blurTooltip"
          v-on:click="handleMobileTap"
        />
      </div>
      <div ref="buildDateTooltip" class="build-date-tooltip">
        <p class="mb-0 font-weight-thin">{{buildDateTooltipContent}}</p>
      </div>
    </FloatLabel>
    <div class="fs-12 text-right mb-2 mt-n3 pr-2 err-text">
      {{ error ? helperText : "" }}
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import {
  formatNumberWithSlash,
  originalDateFormatFromSlash,
} from "../../helper/utils";
export default {
  components: {
    FloatLabel: () => import("./FloatLabel.vue"),
  },
  name: "CustomDatePicker",
  model: {
    prop: "dateValue",
    event: "change",
  },
  data() {
    return {
      buildDateTooltipContent: "Our buyers always want to see the build date. Adding this detail can increase your chance of getting more bids and selling at a higher price.",
    };
  },
  props: {
    dateValue: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    optional: {
      type: Boolean,
      default: () => true,
    },
    format: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    error: {
      type: Boolean,
      default: () => false,
    },
    helperText: {
      type: String,
      default: () => "",
    },
    selectDisabled: Boolean,
    isMobile: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    val: {
      get: function() {
        return this.dateValue;
      },
      set: function(newValue) {
        this.$emit("change", newValue);
      },
    },
  },
  methods: {
    validDateFormat: function(value) {
      return value.length === this.format.length;
    },
    handleInputChange: function(event) {
      const original = originalDateFormatFromSlash(event.target.value);
      if (
        this.validDateFormat(event.target.value) &&
        dayjs(original).isValid()
      ) {
        this.$emit("change", dayjs(original).format(this.format));
        this.$emit("setNextInput");
      }
    },
    hoverTooltip: function() {
      if (!this.isMobile) {
        this.$refs.buildDateTooltip.style.visibility = "visible";
      }
    },
    blurTooltip: function() {
      this.$refs.buildDateTooltip.style.visibility = "hidden";
    },
    handleMobileTap: function(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.isMobile) {
        this.$refs.buildDateTooltip.style.visibility = "visible";
      }
    },
  },
  watch: {
    val: function(value) {
      if (
        value.replace(/\//g, "").length ===
        this.format.replace(/\//g, "").length
      ) {
        this.$nextTick(() => (this.val = formatNumberWithSlash(value)));
      }
    },
  },
};
</script>
<style lang="scss">

#manual-date-picker {
  z-index: 3;
}

.build-date-tooltip {
  position: absolute;
  bottom: 40px;
  right: -10px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #2E312E;
  color: white;
  font-size: 12px;
  text-align: left;
  width: 224px;
  visibility: hidden;
  z-index: 10;
}

@media screen and(min-width: 900px) {
  .build-date-tooltip {
    width: 274px;
  }
}
</style>
